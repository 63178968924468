import {IApi} from "../../utils/api";
import {createPlatformRoutes} from "./PlatformRoutes";
import React, {FC} from "react";
import {translations} from "../../utils/intl"

const createNewNavigation = (TagListPage: any,
                             PlatformApp: any,
                             api: IApi,
                             onPendoClick: () => void): React.FC => {
    const platformRoutes = createPlatformRoutes(TagListPage, api, onPendoClick);
    const PlatformNavigation: FC = () => (
        <PlatformApp routes={platformRoutes} translations={translations}/>
    );
    PlatformNavigation.displayName = 'PlatformNavigation';

    return PlatformNavigation;
};
export default createNewNavigation