import * as R from "ramda"
import {createIntl, createIntlCache, IntlConfig, IntlShape} from "react-intl"
import en from "../content/en.json"
import cs from "../content/cs.json"
import de from "../content/de.json"
import es from "../content/es.json"
import fr from "../content/fr.json"
import ja from "../content/ja.json"
import pl from "../content/pl.json"
import pt from "../content/pt.json"
import zh from "../content/zh.json"
import {useIntl as useNavIntl} from '@platform/cj-platform-navigation'

export const translations:any = {en, cs, de, es, fr, ja, pl, pt, zh}

const createReactIntl: (locale: string) => IntlShape = (locale: string) => {
    const cache = createIntlCache();
    const language = R.toLower(R.take(2, locale)).toString() // uses the navigator local, ex: en-US
    const defaultMessages = en
    const defaultLocale = "en-US"
    const translatedMessages = translations[language]
    const messages:  Record<string, string> = {...defaultMessages, ...translatedMessages}
    const config:IntlConfig = {
        locale: translatedMessages ? locale : defaultLocale,
        messages
    }
    return createIntl(
        config,
        cache)
}

// Custom hook that merges platform navigation translations with our local translations
export const useIntl = () => {
    const platformIntl = useNavIntl();
    const browserLocale = navigator.language;
    const language = R.toLower(R.take(2, browserLocale)).toString();

    // Get local translations
    const localMessages = translations[language] || en;

    // Create new IntlShape with merged messages
    const mergedConfig: IntlConfig = {
        locale: platformIntl.locale,
        messages: {
            ...platformIntl.messages,
            ...localMessages
        }
    };

    const cache = createIntlCache();
    return createIntl(mergedConfig, cache);
};

export const exportsForTesting = {
    createReactIntl
}