import React from 'react'
import {createApi} from "./utils/api";

import {getAuthenticatedFetch} from "./shared/FetchService"
import AuthService from "./shared/AuthService";
import CompanyFeaturesDao from "./dao/FeaturesDao";
import {User} from "./shared/User";
import {useDataFetcher} from "./hooks/dataFetcher";

import {PlatformApp} from '@platform/cj-platform-navigation'

import appConfig from "./appConfig"
import Navigation from "./Components/Navigation/NavigationContainer";
import '@cjdev-internal/design-tokens';
import TagListPage from "./TagList/TagListPage";
import createNewNavigation from "./Components/Navigation/NavigationFactory";

const Dependencies = (): JSX.Element => {

    const authService = new AuthService()
    const authFetch = getAuthenticatedFetch(authService, window.fetch)
    const featuresDao = new CompanyFeaturesDao(appConfig, authFetch)

    const productionApi = createApi(window.fetch, appConfig, authService)

    const legacyUser = new User()
    const result = useDataFetcher(
        () => {
            console.log('fetching features')
            return featuresDao.getFeatures(legacyUser.companyId)
        }, new Set(), [legacyUser.companyId]
    );
    legacyUser.features = result.data

    const goToPendo: () => void = () => {
        if (appConfig.pendoUrl) {
            window.open(appConfig.pendoUrl, '_blank')
        } else {
            alert('no pendo url provided');
        }
    }

    const NavigationComponent = createNewNavigation(TagListPage, PlatformApp, productionApi, goToPendo)

    return <Navigation
        Navigation={NavigationComponent}
    />
}

export default Dependencies
