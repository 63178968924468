import React from "react";

interface INavigationProps {
    Navigation: React.ComponentType
}

const Navigation = ({
                        Navigation,
                    }: INavigationProps): JSX.Element => {
    return <Navigation />;
}

export default Navigation;